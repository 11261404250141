import React, { useCallback, useState, useContext } from "react";
import { useDispatch } from "react-redux";

import YES_NO from "../../../enums/yes-no.json";

import { updateSecondPhase } from "../../api/volunteers";
import { reportError } from "../../utils/error-handler";
import { updateSelf as updateSelfAction } from "../../state/self/self-actions";
import InfoPopupContext from "../../info-popup-context";
import Button from "../../components/button";
import LocalizedLink from "../../components/localized-link";
import StatusModal from "../../components/status-modal";

import DownloadCertificate from "../components/download-certificate";

import styles from "./second-phase-no.module.scss";

const SecondPhaseNo = () => {
  const infoPopupContext = useContext(InfoPopupContext);

  const [submitting, setSubmitting] = useState(false);

  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const submitForm = useCallback(() => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    updateSecondPhase(true)
      .then(
        () => {
          infoPopupContext.display({
            message: "Pasirinkimas išsaugotas"
          });
          setTimeout(() => {
            dispatch(
              updateSelfAction({
                secondPhase: YES_NO.YES,
                secondPhaseConfirmed: true
              })
            );
          });
        },
        err => {
          reportError(err);
          setError(true);
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  }, [submitting, dispatch, setError, setSubmitting]);

  return (
    <>
      <h1 className="bp-heading">Ačiū, kad buvai su „Baltosiomis pirštinėmis“!</h1>

      <p>
        Dėkojame už parodytą pilietiškumą, neabejingumą valstybėje vykstantiems procesams, bei
        indėlį siekiant užtikrinti skaidrius rinkimus.
      </p>

      <p className="bp-center">
        <DownloadCertificate className="bp-standard">Atsisiųsti sertifikatą</DownloadCertificate>
      </p>

      <h2>Kas toliau?</h2>

      <ul className="bp-styled-list">
        <li>
          Padėk mums augti ir mokytis iš savo klaidų – kviečiame užpildyti apklausą apie
          savanorystės patirtį „Baltosiose pirštinėse“:{" "}
          <a
            href="https://forms.gle/hSNx6LX927r25tCR6"
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            https://forms.gle/hSNx6LX927r25tCR6
          </a>
          .
        </li>
        <li>
          Palaikykime ryšį – sek mūsų naujienas socialiniuose tinkluose, kur dalinamės su mūsų
          veikla susijusiomis naujienomis, statistika, kvietimais savanoriauti bei akimirkomis iš
          savanorių gyvenimo.
        </li>
        <li>
          Nors aktyviai nebestebi balsavimo, vis tiek gali{" "}
          <LocalizedLink to="/report-violation" className="bp-link bp-accent">
            pranešti mums apie pastebėtą rinkimų pažeidimą
          </LocalizedLink>
          .
        </li>
      </ul>

      <p className="bp-paragraph">
        Jeigu apsigalvojai ir vis dėlto norėtum stebėti antrąjį merų rinkimų turą kovo 25-30 d.
        dienomis, spustelk šį mygtuką:
      </p>

      <p className="bp-center">
        <Button className="bp-standard" onClick={submitForm} busy={submitting}>
          Noriu stebėti rinkimus!
        </Button>
      </p>

      <p className={`bp-center ${styles.thanks}`}>Iki pasimatymo kituose rinkimuose!</p>

      {error ? (
        <StatusModal
          error
          heading="Nepavyko išsaugoti pasirinkimo"
          description="Apgailestaujame, bet nepavyko išsaugoti Tavo pasirinkimo. Susisiek su mumis el. paštu ir mes padėsime išspręsti šią problemą."
          onClose={() => setError(false)}
        />
      ) : null}
    </>
  );
};

export default SecondPhaseNo;
