import React, { useMemo, useCallback, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import MUNICIPALITY from "../../../enums/municipality-active.json";
import YES_NO from "../../../enums/yes-no.json";

import { reportError } from "../../utils/error-handler";
import { useTranslations } from "../../hooks/translations";
import { useSelf } from "../../hooks/self";
import { updateSelf } from "../../api/volunteers";
import { updateSelf as updateSelfAction } from "../../state/self/self-actions";
import { ELECTION_DATE_RANGE } from "../../utils/calendar";
import { MAX_LENGTH } from "../../utils/validators";
import InfoPopupContext from "../../info-popup-context";
import Form from "../../components/form";
import Dropdown from "../../components/dropdown";
import Checkbox from "../../components/checkbox";
import ToggleToken from "../../components/toggle-token";
import Button from "../../components/button";

import styles from "./preliminary-schedule-form.module.scss";

const doesDateMatchAvailability = (date, item) => item.dateTime.valueOf() === date.valueOf();

const isAvailableDate = (date, availability) =>
  availability.some(item => doesDateMatchAvailability(date, item));

const constructScheduleFields = availability =>
  ELECTION_DATE_RANGE.reduce((fields, date, dateIndex) => {
    const fieldId = `scheduleDay${dateIndex}`;
    fields[fieldId] = {
      value: availability && isAvailableDate(date, availability)
    };
    return fields;
  }, {});

const processSchedule = formData => {
  formData.availability = ELECTION_DATE_RANGE.map((date, dateIndex) => {
    const fieldId = `scheduleDay${dateIndex}`;

    let dateFrom;
    let dateTo;
    if (formData[fieldId]) {
      dateFrom = new Date(date);
      dateFrom.setHours(8, 0, 0, 0);

      dateTo = new Date(date);
      dateTo.setHours(20, 0, 0, 0);
    }

    return dateFrom || dateTo
      ? {
          from: dateFrom,
          to: dateTo
        }
      : null;
  }).filter(value => !!value);

  Object.keys(formData).forEach(key => {
    if (key.startsWith("scheduleDay")) {
      delete formData[key];
    }
  });

  formData.scheduleVoteCount = formData.scheduleVoteCount ? YES_NO.YES : YES_NO.NO;
};

const PreliminaryScheduleForm = ({ submitLabel, onComplete }) => {
  const t = useTranslations("clientRoutes.preliminarySchedule");

  const enums = useTranslations("enums");

  const [submitting, setSubmitting] = useState(false);

  const { userData } = useSelf();

  const formFields = useMemo(
    () => ({
      municipalities: {
        value:
          userData.municipalities && userData.municipalities.length > 0
            ? userData.municipalities
            : [userData.municipality],
        validators: [
          {
            validate: MAX_LENGTH(6),
            message: "tooManyMunicipalities"
          }
        ]
      },
      ...constructScheduleFields(userData.availability),
      scheduleVoteCount: {
        value: userData.scheduleVoteCount === YES_NO.YES
      }
    }),
    []
  );

  const municipalityDropdownValues = useMemo(
    () =>
      Object.keys(MUNICIPALITY).map(key => ({
        key,
        value: enums.municipality[key]
      })),
    []
  );

  const dispatch = useDispatch();

  const infoPopupContext = useContext(InfoPopupContext);

  const submitForm = useCallback(
    formData => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      const data = {
        ...formData
      };

      processSchedule(data);

      updateSelf(data)
        .then(
          () => {
            infoPopupContext.display({
              message: t.success
            });
            // Timeout necessary because calling `display` on this context
            // triggers a re-render of this component
            setTimeout(() => {
              dispatch(
                updateSelfAction({
                  ...data,
                  schedulesUpdatedAt: new Date()
                })
              );

              window.scrollTo({
                top: 0
              });

              onComplete();
            });
          },
          err => {
            reportError(err, "Failed to update preliminary schedule");
            infoPopupContext.display({
              error: true,
              message: t.error
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    [submitting, onComplete, dispatch, setSubmitting]
  );

  return (
    <Form fields={formFields} onSubmit={submitForm} displayGlobalError errorMessages={t.errors}>
      {({ setFieldValue, fields }) => (
        <>
          <Dropdown
            id="municipalities"
            label={t.municipalities}
            options={municipalityDropdownValues}
            field={fields.municipalities}
            onChange={setFieldValue}
            placeholder={t.municipalities}
            multiple
          />

          <div className="bp-label">{t.schedule}</div>

          <div className={styles.days}>
            {ELECTION_DATE_RANGE.map((date, dateIndex) => {
              const fieldId = `scheduleDay${dateIndex}`;

              return (
                <ToggleToken
                  key={date.toString()}
                  id={fieldId}
                  field={fields[fieldId]}
                  onChange={setFieldValue}
                >
                  {enums.month[date.getMonth()]} {date.getDate()} {enums.timeUnit.DAY.short},{" "}
                  {enums.weekday[date.getDay()]}
                </ToggleToken>
              );
            })}
          </div>

          <Checkbox
            id="scheduleVoteCount"
            field={fields.scheduleVoteCount}
            onChange={setFieldValue}
          >
            {t.voteCount}
          </Checkbox>

          <Button type="submit" className="bp-standard bp-submit-button" busy={submitting}>
            {submitLabel || t.submit}
          </Button>
        </>
      )}
    </Form>
  );
};

PreliminaryScheduleForm.propTypes = {
  onComplete: PropTypes.func,
  submitLabel: PropTypes.string
};

PreliminaryScheduleForm.defaultProps = {
  onComplete: () => {},
  submitLabel: null
};

export default PreliminaryScheduleForm;
