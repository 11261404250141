import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

import YES_NO from "../../../enums/yes-no.json";

import logoNoText from "../../assets/images/logo-no-text.svg";
import logoFacebook from "../../assets/images/facebook.svg";

import { ROUTE_CONFIG } from "../../client-routes/route-config";
import { useTranslations } from "../../hooks/translations";
import { useSelf, useCoordinators } from "../../hooks/self";
import { useLogout } from "../../hooks/logout";
import { useElectionOver } from "../../hooks/election-over";

import LocalizedLink from "../localized-link";
import Button from "../button";
import Hamburger from "../hamburger";
import LogoLink from "../logo-link";

import styles from "./sidebar.module.scss";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */

const NAVIGATION = ROUTE_CONFIG.filter(({ root }) => root);

const DashboardSidebar = ({ className }) => {
  const t = useTranslations("pages.dashboard.sidebar");

  const { claims, userData } = useSelf();

  const { coordinators } = useCoordinators();

  const myCoordinator = useMemo(() => {
    if (!coordinators) {
      return null;
    }

    return coordinators[Math.floor(Math.random() * coordinators.length)];
  }, [coordinators]);

  const links = useMemo(
    () =>
      NAVIGATION.filter(({ roles, customAccessCheck }) =>
        customAccessCheck
          ? customAccessCheck(userData, claims)
          : !roles || roles.length === 0 || roles.some(role => claims[role])
      ).map(({ path, label, exact }) => ({
        url: `/dashboard${path}`,
        label: t[label],
        exact
      })),
    [claims, userData]
  );

  const logout = useLogout({
    redirectTo: "/login"
  });

  const [menuVisible, setMenuVisible] = useState(false);

  const closeMenu = useCallback(() => {
    setMenuVisible(false);
  }, [setMenuVisible]);

  const isElectionOver = useElectionOver();

  return (
    <div className={`${className} ${styles.sidebar}`}>
      <header className={`bp-between ${styles.header}`}>
        <Hamburger
          open={menuVisible}
          onClick={() => setMenuVisible(true)}
          className={styles.hamburger}
          aria-haspopup="false"
          aria-expanded="false"
          aria-hidden
        />

        <span>
          <span className={styles.headerName}>{userData && userData.name}</span>
          <img src={logoNoText} height="32" alt="" className={styles.headerLogo} />
        </span>
      </header>

      <div
        className={`bp-opaque-background ${styles.overlay} ${menuVisible ? styles.visible : ""}`}
        onClick={closeMenu}
      />

      <nav className={`${styles.nav} ${menuVisible ? styles.visible : ""}`}>
        <div className={styles.noFlex}>
          <LogoLink />
        </div>

        {links.map(({ url, label, exact }) => (
          <div key={label} className={styles.noFlex}>
            <hr />

            <LocalizedLink
              to={url}
              className="bp-link"
              partiallyActive={!exact}
              activeClassName={styles.active}
              onClick={closeMenu}
            >
              {label}
            </LocalizedLink>
          </div>
        ))}

        <div className={styles.spacer} />

        <div className={styles.facebook}>
          <div className={styles.facebookBox}>
            <img src={logoFacebook} alt="" height="32" />
            <div>Prisijunk prie stebėtojų Facebook grupės!</div>
            <a
              href="https://www.facebook.com/groups/9297577790272873"
              target="_blank"
              rel="noreferrer noopener"
              className={`bp-button ${styles.facebookJoin}`}
            >
              Prisijungti
            </a>
          </div>
        </div>

        {!isElectionOver &&
        myCoordinator &&
        userData &&
        (userData.secondPhase === YES_NO.YES || userData.secondPhaseOnly) ? (
          <div className={styles.coordinator}>
            <div className={styles.coordinatorBox}>
              <div>Mano koordinatorius</div>
              <div>
                <b>{myCoordinator.name}</b>
              </div>
              <div>
                <a href={`tel:${myCoordinator.phone}`} className={styles.coordinatorPhone}>
                  {myCoordinator.phone}
                </a>
              </div>
            </div>
          </div>
        ) : null}

        <p className={styles.name}>
          {userData && userData.name} {userData && userData.surname}
        </p>

        <hr />

        <Button onClick={logout} className={`bp-navigation ${styles.noFlex}`}>
          {t.logout}
        </Button>

        <hr />

        <LocalizedLink to="/" className={`bp-link ${styles.noFlex}`}>
          {t.home}
        </LocalizedLink>
      </nav>
    </div>
  );
};

DashboardSidebar.propTypes = {
  className: PropTypes.string
};

DashboardSidebar.defaultProps = {
  className: ""
};

export default DashboardSidebar;
