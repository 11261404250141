import pdfmake from "pdfmake/build/pdfmake";

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import background from "!!raw-loader!../assets/certificate/background.svg";
import VFS from "../assets/certificate/vfs_fonts";
import { getCertificateId } from "../api/volunteers";

pdfmake.vfs = VFS;

pdfmake.fonts = {
  Rubik: {
    normal: "Rubik-Regular.ttf",
    bold: "Rubik-Bold.ttf",
    italics: "Rubik-Italic.ttf",
    bolditalic: "Rubik-BoldItalic.ttf"
  }
};

const COLORS = {
  LIGHT: "#f5f5f7",
  DARK: "#2b2f3d"
};

export const generateCertificate = ({ name, surname }) =>
  getCertificateId().then(certificateId => {
    const invoiceInput = {
      info: {
        // =====      RESET       =====
        title: `Stebėtojo sertifikatas BP-2025-Kovas-${certificateId}`,
        author: "Baltosios pirštinės",
        creator: "Baltosios pirštinės",
        producer: "Baltosios pirštinės"
      },
      background: [
        {
          svg: background,
          width: 596
        }
      ],
      content: [
        {
          text: "Visuomeninė organizacija „Baltosios pirštinės“",
          fontSize: 12,
          margin: [0, 117, 0, 0]
        },
        {
          text: "PAŽYMĖJIMAS",
          fontSize: 25,
          bold: true,
          italic: true,
          margin: [0, 40, 0, 0]
        },
        {
          // =====      RESET       =====
          text: `Nr. BP-2025-Kovas-${certificateId}`,
          fontSize: 11,
          margin: [0, 10, 0, 0]
        },
        {
          text: `${name} ${surname}`,
          fontSize: 22,
          margin: [0, 45, 0, 45]
        },
        {
          text:
            // =====      RESET       =====
            "2025 metų Lietuvos Respublikos kovo mėnesio pirmalaikiuose Jonavos ir Joniškio rajonų, Panevėžio miesto savivaldybių merų rinkimuose savanoriškai vykdė nepriklausomo stebėtojo pareigas ir prisidėjo prie rinkimų skaidrumo užtikrinimo.",
          style: "body"
        },
        {
          text: "Ačiū, kad drauge kuriate skaidresnę Lietuvą bei stipresnę pilietinę visuomenę!",
          style: "body"
        },
        {
          text: "Visuomeninė organizacija „Baltosios pirštinės“",
          fontSize: 11.5,
          bold: true,
          alignment: "left",
          color: COLORS.LIGHT,
          margin: [40, 55, 0, 0]
        },
        {
          text: "Vadovas Valdemar Baran",
          fontSize: 11.5,
          alignment: "left",
          color: COLORS.LIGHT,
          margin: [40, 8, 0, 0]
        },
        {
          text: "Vilnius",
          style: "small",
          margin: [0, 185, 0, 0]
        },
        {
          // =====      RESET       =====
          text: "2025 m.",
          style: "small",
          margin: [0, 1, 0, 0]
        }
      ],
      styles: {
        body: {
          fontSize: 14,
          italics: true,
          lineHeight: 1.2,
          margin: [60, 0]
        },
        small: {
          fontSize: 10,
          color: COLORS.LIGHT
        }
      },
      defaultStyle: {
        font: "Rubik",
        color: COLORS.DARK,
        alignment: "center"
      }
    };

    // =====      RESET       =====
    pdfmake.createPdf(invoiceInput).download(`Sertifikatas-BP-2025-Kovas-${certificateId}.pdf`);
  });
